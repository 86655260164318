// assets
import { IconSettings, IconFileSearch, IconHome, IconReportAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconSettings,
    IconFileSearch,
    IconHome,
    IconReportAnalytics
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: '',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'process',
            title: 'Procesos',
            type: 'collapse',
            icon: icons.IconFileSearch,
            auth: false,
            allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta'],
            children: [
                {
                    id: 'documents',
                    title: 'Documentos',
                    type: 'item',
                    url: '/process/documents',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'tracking',
                    title: 'Tracking',
                    type: 'item',
                    url: '/process/tracking',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'ocCheck',
                    title: 'Busqueda e Ingreso de OC Manual',
                    type: 'item',
                    url: '/process/ocCheck',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'ncNdCheck',
                    title: 'Busqueda e Ingreso de NC/ND Manual',
                    type: 'item',
                    url: '/process/ncNdCheck',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'invoiceEntry',
                    title: 'Ingreso de Factura Vendor',
                    type: 'item',
                    url: '/process/invoiceEntry',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'arEntry',
                    title: 'Ingreso manual AR',
                    type: 'item',
                    url: '/process/ar/index',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'sliSend',
                    title: 'Re-envio a SLI',
                    type: 'item',
                    url: '/process/sliSend',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'xmlValidation',
                    title: 'Validacion XML',
                    type: 'item',
                    url: '/process/xmlValidation',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'xmlUpload',
                    title: 'Carga de XML',
                    type: 'item',
                    url: '/process/xmlUpload',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                }
            ]
        },
        {
            id: 'maintenance',
            title: 'Mantenedores',
            type: 'collapse',
            icon: icons.IconSettings,
            auth: false,
            allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador'],
            children: [
                {
                    id: 'roles',
                    title: 'Roles',
                    type: 'item',
                    url: '/maintainer/roles',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador']
                },
                {
                    id: 'comissions',
                    title: 'Comisiones',
                    type: 'item',
                    url: '/maintainer/commissions',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'invoices',
                    title: 'Rango de Folios',
                    type: 'item',
                    url: '/maintainer/invoices',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'providers',
                    title: 'Proveedores',
                    type: 'item',
                    url: '/maintainer/providers',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'paymentTerm',
                    title: 'Condicion de Pago',
                    type: 'item',
                    url: '/maintainer/paymentTerm',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'workflowRequests',
                    title: 'Solicitudes',
                    type: 'item',
                    url: '/maintainer/workflowRequests',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Autorizador', 'Editor']
                },
                {
                    id: 'nif',
                    title: 'NIF',
                    type: 'item',
                    url: '/maintainer/nif',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Autorizador', 'Editor']
                },
                {
                    id: 'state',
                    title: 'Estados',
                    type: 'item',
                    url: '/maintainer/state',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Autorizador', 'Editor']
                },
                {
                    id: 'city',
                    title: 'Ciudades',
                    type: 'item',
                    url: '/maintainer/city',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Autorizador', 'Editor']
                },
                {
                    id: 'hierarchy',
                    title: 'Jerarquías',
                    type: 'item',
                    url: '/maintainer/hierarchy',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador']
                },
                {
                    id: 'UpdateReference',
                    title: 'Actualizar Referencia',
                    type: 'item',
                    url: '/maintainer/UpdateReference',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista']
                }
            ]
        },
        {
            id: 'reports',
            title: 'Reportes',
            type: 'collapse',
            breadcrumbs: false,
            auth: false,
            allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta'],
            icon: icons.IconReportAnalytics,
            children: [
                {
                    id: 'generalReport',
                    title: 'Reporte General',
                    type: 'item',
                    url: '/reports/general',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'managementReport',
                    title: 'Gestion Falabella Trading',
                    type: 'item',
                    url: '/reports/management',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'processedDocuments',
                    title: 'Documentos Procesados',
                    type: 'item',
                    url: '/reports/processedDocs',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'availableDocuments',
                    title: 'Documentos disponible de Pago',
                    type: 'item',
                    url: '/reports/availableDocs',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'hanaReports',
                    title: 'Hana',
                    type: 'item',
                    url: '/reports/hanaReports',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'pendingInvoice',
                    title: 'Facturas pendientes',
                    type: 'item',
                    url: '/reports/pendingInvoice',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'downloadPdf',
                    title: 'Descarga de Documentos Biller',
                    type: 'item',
                    url: '/reports/downloadDocumentsBiller',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'downloadXml',
                    title: 'Descarga de Documentos XML',
                    type: 'item',
                    url: '/reports/downloadXmlDocuments',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'downloadExcelData',
                    title: 'Descargar versiones documento Excel',
                    type: 'item',
                    url: '/reports/versionesExcel',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                },
                {
                    id: 'DailyReport',
                    title: 'Reporte Diario',
                    type: 'item',
                    url: '/reports/dailyReport',
                    breadcrumbs: false,
                    auth: false,
                    allowedRoles: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta']
                }
            ]
        }
    ]
};

export default pages;
